<template>
    <div>
        <b-card class="reports-list">
            <b-card-header class="card-header-main">
                <b-row class="reports-list-header">
                    <b-col md="2">
                        <project-router-link to="/customqueries/create">
                            <b-button class="text-nowrap" variant="primary">
                                <i class="icon-plus mr-1"></i>Create Custom Query
                            </b-button>
                        </project-router-link>
                    </b-col>
                    <b-col md="2">
                        <project-router-link to="/customqueries/categories">
                            <b-button class="text-nowrap" variant="primary">
                                <i class="icon-plus mr-1"></i>Categories
                            </b-button>
                        </project-router-link>
                    </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <wit-table
                    mode="server"
                    :fields="customQueriesColumns"
                    :request-function="requestFunction"
                    striped
                    paginated
                    hide-per-page-selector
                    ref="customQueriesTable"
                    :default-per-page="10"
                >
                    <template v-slot:cell(lastModified)="{item}">{{ parseLocalDate(item.updatedAt) }}</template>
                    <template v-slot:cell(dedicated)="{item}">
                        <div class="query-dedicated-row">
                            <TrueOrFalseIcon :value="item.access ? item.access.some(a => a.id === 'All') : false" />
                        </div>
                    </template>

                    <template v-slot:cell(actions)="{item}">
                        <div class="button-row">
                            <DetailsAction :routerLink="'customqueries/' + item.id" />
                        </div>
                    </template>
                </wit-table>
            </b-card-body>
        </b-card>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import VueNotifications from 'vue-notifications'

import DetailsAction from '@/components/Actions/DetailsAction.vue'
import Loading from '@/components/loading.vue'
import TrueOrFalseIcon from '@/components/TrueOrFalseIcon.vue'
import WitTable from '@/components/WitTable.vue'

export default {
    components: {
        DetailsAction,
        Loading,
        TrueOrFalseIcon,
        WitTable,
    },
    mounted() {
        this.reloadCustomQueriesTableInterval = setInterval(this.reloadCustomQueriesTable, 5000)
    },
    created() {
        const {queryCreated, queryUpdated} = this.$route.query

        if (queryCreated) {
            this.$router.replace({query: {}})
            this.queryCreated()
        }

        if (queryUpdated) {
            this.$router.replace({query: {}})
            this.queryUpdated()
        }
    },
    // updatedAt, all or dedicated, remarketing
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
    },
    notifications: {
        queryCreated: {
            type: VueNotifications.types.success,
            title: 'Custom Query has been successfully created.',
        },
        queryUpdated: {
            type: VueNotifications.types.success,
            title: 'Custom Query has been successfully updated.',
        },
    },
    destroyed() {
        clearInterval(this.reloadCustomQueriesTableInterval)
    },
    data() {
        return {
            showDataStudioReports: false,
            customQueryError: {},
            customQueriesApiUrl: `${process.env.VUE_APP_NODE_API_HOST}/customQuery`,
            customQueriesColumns: ['name', 'lastModified', 'dedicated', 'actions'],
        }
    },
    methods: {
        async requestFunction(params) {
            const response = await this.axios
                .get(this.customQueriesApiUrl, {
                    params: {
                        ...params,
                        projectId: this.$store.state.project.active.id,
                    },
                })
                .catch(
                    function(e) {
                        this.dispatch('error', e)
                    }.bind(this)
                )

            return {
                data: response.data.data,
                count: response.data.metadata.count,
            }
        },
        reloadCustomQueriesTable() {
            if (!document.hidden && this.$refs.customQueriesTable)
                this.$refs.customQueriesTable.fetchData({silent: true})
        },
        openReport(report) {
            window.open(report.url, '_blank')
        },
        parseLocalDate(_date) {
            const date = new Date(_date)
            return !isNaN(date.getTime())
                ? `${date.toLocaleDateString('pl-PL')} ${date.toLocaleTimeString('pl-PL')}`
                : null
        },
    },
}
</script>

<style <style lang="scss">
.close-create-report {
    font-weight: 600;
    position: absolute;
    right: 0.5%;
    top: 5%;
    cursor: pointer;
}
.query-dedicated-row {
    padding-left: 1.7rem;
}
.query-remarketing-row {
    padding-left: 2.2rem;
}
</style>
